import { LitElement, css, html } from 'lit'
import { createRef, ref } from 'lit/directives/ref.js'
import { debounce, isBreakpoint } from '#js/components/utils'
import { atBreakpoint } from '#js/components/styles'

/**
 * Sidebar navigation on desktop, hamburger menu on mobile.
 */
export class BurgerSidebar extends LitElement {
  dialogRef = createRef()

  static styles = css`
    /* the burger-sidebar is invisible on mobile before the LitElement is upgraded */

    :host {
      display: block !important;
    }

    ${atBreakpoint('mobile', css`
      .card {
        max-height: calc(100dvh - 2 * var(--space));

        .card__content {
          flex: 0 1 100%;
          overflow: auto;
          overscroll-behavior: contain;
        }
      }
    `)}

    button {
      appearance: none;
      border: none;
      width: 2em;
      height: 2em;
      position: relative;
      padding: 0;
      margin: 0;
      color: var(--interactive-color);
      background-color: transparent;
    }

    button.close {
      position: absolute;
      top: 1em;
      right: 1em;
      text-align: center;
      line-height: 1em;
      font-weight: bold;
      font-family: sans-serif;
    }

    .burger, .burger::before, .burger::after {
      content: " ";
      display: block;
      position: absolute;
      background-color: var(--interactive-color);
      width: 1.5em;
      height: 0.2em;
      border-radius: 0.1em;
    }

    .burger {
      top: 0.4em;
      left: 0.25em;
    }

    .burger::before {
      top: 0.5em;
    }

    .burger::after {
      top: 1em;
    }
  `

  open () {
    this.shadowRoot.querySelector('dialog').showModal()
  }

  close () {
    this.shadowRoot.querySelector('dialog').close()
  }

  debouncedResize = debounce(() => {
    this.requestUpdate()
  }, 100)

  firstUpdated () {
    window.addEventListener('resize', () => {
      this.debouncedResize()
    })
  }

  render () {
    return html`
      <link rel="stylesheet" href="${window.styleFilePath}">
      ${this.getDesktop()}
      ${this.getMobile()}
    `
  }

  getDesktop () {
    if (isBreakpoint('desktop')) {
      return html`
        <div style="height: 100%">
          <slot name="dialog-title"></slot>
          <slot></slot>
        </div>
      `
    }
  }

  getMobile () {
    if (isBreakpoint('mobile')) {
      return html`
        <button @click="${this.open}">
          <span class="burger"></span>
        </button>
        <dialog class="dialog" ${ref(this.dialogRef)}>
          <div class="card">
            <div class="dialog__head">
              <slot name="dialog-title" style="grid-area: title;"></slot>
              <button class="cross" style="grid-area: close;" @click="${this.close}"></button>
            </div>
            <div class="card__content">
              <slot></slot>
            </div>
            <slot name="dialog-button"></slot>
          </div>
        </dialog>
      `
    }
  }
}

customElements.define('burger-sidebar', BurgerSidebar)
